<template>
  <b-card>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Danh sách vé trả thưởng</h4>
      </b-col>
      <b-col cols="3">
        <b-form-group id="print-type-select" label="Loại vé in" label-for="print-type-filter">
          <b-form-select
            id="print-type-filter"
            v-model="printTypesFilterData"
            :options="printTypesFilter"
            @change="fetchData"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group id="opc-status-select" label="Trạng thái trả thưởng" label-for="opc-status-filter">
          <b-form-select
            id="opc-status-filter"
            v-model="opcWinningStatusFilterData"
            :options="opcWinningStatusFilter"
            @change="fetchData"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group id="input-device-filter" label="Device Id" label-for="device-filter">
          <b-form-input
            id="device-filter"
            v-model="device_id"
            type="text"
            placeholder="Search device..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group id="input-ticket-id-filter" label="Ticket Id" label-for="ticket-id-filter">
          <b-form-input
            id="ticket-id-filter"
            v-model="filterDisplayTicketId"
            type="text"
            placeholder="Search Ticket..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- Filter game  -->
      <b-col cols="1">
        <b-form-group id="input-screen-filter" label="Game" label-for="screen-filter">
          <b-form-select id="screen-filter" v-model="filter_game" :options="allGameAdsList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <!-- filter period no -->
      <b-col cols="2">
        <b-form-group id="input-from-date" label="Period on:" label-for="period-no-filter">
          <b-form-input
            id="period-no-filter"
            v-model="filterPeriodNo"
            :disabled="isFilterByGame"
            type="text"
            placeholder="Search period..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <!-- filter date from -->
      <b-col cols="2">
        <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
          <datePicker v-model="filter_from" :config="dpOptions" @dp-change="fetchData"></datePicker>
        </b-form-group>
      </b-col>

      <!-- filter date to -->
      <b-col cols="2">
        <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
          <datePicker v-model="filter_to" :config="dpOptions" @dp-change="fetchData"></datePicker>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center"
    >
      <template v-slot:cell(game)="item">
        <div class="font-weight-bold text-uppercase">{{ item.value }}</div>
      </template>
      <template v-slot:cell(actual_amount)="item">
        <div>{{ formatPrice(item.value) }}</div>
      </template>

      <template v-slot:cell(print_type)="item">
        <b-badge v-if="item.value" variant="info">Đại lý</b-badge>
        <b-badge v-else variant="success">Tự động</b-badge>
      </template>
      <template v-slot:cell(opc_winning_status)="item">
        <b-form-checkbox
          class="customSwitch"
          switch
          size="lg"
          :checked="!!item.value"
          @click.native.prevent="handleChangeStatus(item)"
        >
          <b-badge v-if="item.value" variant="success">Đã in vé trả thưởng</b-badge>
          <b-badge v-else variant="warning">Chưa in vé trả thưởng</b-badge>
        </b-form-checkbox>
      </template>
      <template v-slot:cell(image_preview)="item">
        <img :src="item.value[0].src" style="height: 50px" @click="openGallery(item.item.id)" />
        <LightBox
          :ref="'lightbox_' + item.item.id"
          :media="item.value"
          :show-light-box="false"
          :show-thumbs="false"
        ></LightBox>
      </template>
      <template v-slot:cell(actions)="item">
        <div v-if="rewardRule.min_manual_amount && item.item.actual_amount > rewardRule.min_manual_amount">
          <v-icon class="text-success" @click="handleManualReward(item)"> fas fa-wallet</v-icon>
        </div>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline style="margin-right: 20px; margin-bottom: 1rem">
          <label class="mr-sm-2" for="per-page">Rows per page: </label>
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
// import { formatDateForView } from '@/utils/time.js';

import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import datePicker from 'vue-bootstrap-datetimepicker';
import LightBox from 'vue-image-lightbox';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

export default {
  name: 'Tickets',
  components: { datePicker, LightBox },
  data() {
    return {
      isFilterByGame: true,
      isLoading: true,
      search: '',
      isBusy: false,
      isBusyRewardRule: false,
      filterDisplayTicketId: '',
      filterPeriodNo: '',
      filter_game: null,
      filter_from: '',
      filter_to: '',
      device_id: '',
      // statusList: [
      //   { value: 1, text: 'Printed', variant: 'success' },
      //   { value: 2, text: 'Canceled', variant: 'danger' },
      //   { value: 0, text: 'Pending', variant: 'warning' }
      // ],
      headers: [
        { label: 'ID', key: 'id' },
        { label: 'Kỳ', key: 'period_no' },
        { label: 'Tổng', key: 'actual_amount' },
        { label: 'Full serial', key: 'full_serial' },
        { label: 'Game', key: 'game' },
        { label: 'Loại vé in', key: 'print_type' },
        { label: 'Trạng Thái', key: 'opc_winning_status' },
        { label: 'Ảnh vé', key: 'image_preview' },
        { label: 'Mã vé OPC', key: 'opc_ticket_code' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      allGameAdsList: [],
      printTypesFilter: [
        { value: 1, text: 'Đại lý' },
        { value: 0, text: 'Tự động' }
      ],
      printTypesFilterData: null,
      opcWinningStatusFilter: [
        { value: 1, text: 'Đã in vé trả thưởng' },
        { value: 0, text: 'Chưa in vé trả thưởng' }
      ],
      dpOptions: {
        format: 'MM/DD/YYYY',
        sideBySide: true
      },
      opcWinningStatusFilterData: null,
      // Modal data
      items: [],
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      rewardRule: {}
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getRewardRule();
    this.fetchData();
    this.fetchGame();
  },
  mounted() {},
  methods: {
    openGallery(id) {
      this.$refs[`lightbox_${id}`].showImage(0);
    },
    async fetchGame() {
      const res = await ApiService.get('allgame');

      this.allGameAdsList = res?.data?.data || [];
      this.allGameAdsList.forEach((item) => {
        item.value = item.handler;
      });
    },
    fetchData() {
      this.isBusy = true;
      if (this.filter_game !== null) {
        this.isFilterByGame = false;
      } else {
        this.isFilterByGame = true;
      }
      ApiService.setHeader();
      ApiService.query('/tickets/rewards', {
        params: {
          limit: this.perPage,
          offset: this.currentPage,
          print_type: this.printTypesFilterData,
          opc_winning_status: this.opcWinningStatusFilterData,
          ticket_id: this.filterDisplayTicketId,
          device_id: this.device_id,
          game: this.filter_game,
          from: this.filter_from,
          to: this.filter_to,
          period_no: this.filterPeriodNo
        }
      })
        .then((res) => {
          const { data, status } = res.data;
          if (status) {
            this.items = data.ticketList.map((item) => {
              item.image_preview = [{ thumb: item.image_preview, src: item.image_preview }];
              return item;
            });
            this.rows = data.total;
          }
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: 'Lấy dữ liệu không thành công. Vui lòng thử lại sau.',
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    getRewardRule() {
      this.isBusyRewardRule = true;

      ApiService.setHeader();
      ApiService.query('/reward-rules', {
        params: {
          limit: 1,
          offset: 1,
          status: 1
        }
      })
        .then((res) => {
          const { data, status } = res.data;
          if (status) {
            this.rewardRule = data.rewardRuleList?.[0];
          }
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: 'Lấy dữ liệu không thành công. Vui lòng thử lại sau.',
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        })
        .finally(() => {
          this.isBusyRewardRule = false;
        });
    },
    async handleChangeStatus(item) {
      ApiService.setHeader();
      ApiService.post('/tickets/rewards', {
        ticket_id: item.item.id,
        opc_winning_status: item.item.opc_winning_status ? 0 : 1
      })
        .then(() => {
          this.$bvToast.toast('Cập nhật trạng thái trả thưởng thành công.', {
            title: 'Thông báo cập nhật',
            variant: 'success',
            autoHideDelay: 5000,
            noCloseButton: true
          });

          this.$set(this.items[item.index], 'opc_winning_status', !item.item.opc_winning_status);
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    handleManualReward(item) {
      ApiService.setHeader();
      ApiService.patch(`/tickets/${item.item.id}/reward`, {
        ticketId: item.item.id
      })
        .then(() => {
          this.$bvToast.toast('Trả thưởng thành công.', {
            title: 'Thông báo trả thưởng',
            variant: 'success',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500)
  }
};
</script>

<style lang="scss">
.customSwitch {
  display: inline-block;
  .custom-control-label {
    cursor: pointer;
  }
}
</style>
